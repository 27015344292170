<template>
  <div class="my-3 mx-4">
    <div class="row">
      <div class="col-12">
        <div class="heading">Reservation Check-In</div>
        <p class="text-muted">
          Check in a specific reservation. As part of this action, some details
          of the reservation can be updated here without any additional fees.
        </p>
        <AdminReservationSummary :reservation="reservation" />
        <AdminReservationContact
          header="Customer"
          :contact="reservation.customerContact"
        />
        <AdminReservationComments :reservationId="+reservationId" />
        <AdminReservationHistory :reservationId="+reservationId" />
        <ValidationObserver
          ref="checkInForm"
          tag="form"
          id="checkInForm"
          @submit.prevent="onSubmit"
        >
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-2 mb-0">Check-In Details</h4>
            </div>
            <div class="card-body">
              <FormErrorAlert
                v-if="errors.length > 0"
                :errors="errors"
                :formRef="this.$refs.checkInForm"
              />
              <DateInput
                vid="startDate"
                :rules="
                  `required|isDate|betweenDates:'${this.reservation.arrivalDate}','${this.reservation.departureDate}'`
                "
                name="Check-In Date"
                id="checkedInDate"
                :dateDisabledFn="maxCheckIn"
                :initialDate="initialDate"
                v-model="checkedInDate"
                class="mb-3"
                :immediate="true"
              />
              <TextInput
                name="Number Of People"
                id="numberOfAdults"
                :rules="peopleRule"
                v-model="numberOfAdults"
                class="mb-2"
              />
              <p class="pl-1" v-if="basePeople || maxPeople">
                ({{ basePeople ? "Min:" + basePeople + " " : ""
                }}{{ maxPeople ? "Max: " + maxPeople : "" }})
              </p>
              <TextInput
                name="Number Of Vehicles"
                id="numberOfVehicles"
                :rules="vehicleRule"
                v-model="numberOfVehicles"
                class="mb-2"
              />
              <p class="pl-1" v-if="baseVehicles || maxVehicles">
                ({{ baseVehicles ? "Min:" + baseVehicles + " " : ""
                }}{{ maxVehicles ? "Max: " + maxVehicles : "" }})
              </p>
            </div>
          </div>
          <button class="btn btn-success mr-2">
            <i class="fas fa-calendar-check"></i> CHECK IN
          </button>
          <button class="btn btn-primary" type="button" @click="cancel">
            CANCEL
          </button>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AdminReservationContact from "@/components/admin/AdminReservationContact.vue";
import AdminReservationSummary from "@/components/admin/AdminReservationSummary.vue";
import AdminReservationComments from "@/components/admin/AdminReservationComments.vue";
import AdminReservationHistory from "@/components/admin/AdminReservationHistory.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import LocationService from "@/services/LocationService.js";
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";

export default {
  name: "AdminReservationCheckIn",
  title: "Admin - Reservation Check-In",
  components: {
    AdminReservationContact,
    AdminReservationSummary,
    AdminReservationComments,
    AdminReservationHistory,
    ValidationObserver,
    TextInput,
    DateInput,
    FormErrorAlert
  },
  props: {
    reservationId: String
  },
  data() {
    return {
      reservation: {},
      checkedInDate: "",
      numberOfAdults: "0",
      numberOfVehicles: "0",
      errors: []
    };
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    initialDate() {
      return this.reservation.arrivalDate
        ? moment(this.reservation.arrivalDate, "MM-DD-YYYY").format(
            "YYYY-MM-DD"
          )
        : "";
    },
    peopleRule() {
      return `required|between:${this.basePeople ?? 0},${this.maxPeople ?? 99}`;
    },
    vehicleRule() {
      return `required|between:${this.baseVehicles ?? 0},${this.maxVehicles ??
        99}`;
    },
    basePeople() {
      const attr = this.reservation?.spot?.attributes?.find(
        x => x.globalAttributeId === 203
      );
      return attr ? attr.value : null;
    },
    baseVehicles() {
      const attr = this.reservation?.spot?.attributes?.find(
        x => x.globalAttributeId === 204
      );
      return attr ? attr.value : null;
    },
    maxPeople() {
      const attr = this.reservation?.spot?.attributes?.find(
        x => x.globalAttributeId === 133
      );
      return attr ? attr.value : null;
    },
    maxVehicles() {
      const attr = this.reservation?.spot?.attributes?.find(
        x => x.globalAttributeId === 134
      );
      return attr ? attr.value : null;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.checkInForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.checkInForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.checkInForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          try {
            this.$store.commit("auth/setLoading", true);
            this.errors = [];
            const spotReservationService = new AdminSpotReservationService(
              this.tenantId
            );
            const response = await spotReservationService.checkIn({
              reservationId: this.reservationId,
              checkedInDate: this.checkedInDate,
              numberOfAdults: this.numberOfAdults,
              numberOfVehicles: this.numberOfVehicles
            });
            if (response.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Reservation successfully checked in",
                layer: "admin"
              });
              this.cancel();
            } else {
              window.scrollTo(0, 0);
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (e) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    async getReservationDetail() {
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        const spotService = new LocationService(this.tenantId);
        const response = await spotReservationService.getReservationDetail(
          this.reservationId
        );
        const spotResponse = await spotService.getSpotDetails(
          response.data.spotId
        );
        this.reservation = response.data;
        this.reservation.spot = spotResponse.spotDetails;
        this.numberOfAdults = response.data.numberOfAdults ?? "0";
        this.numberOfVehicles = response.data.numberOfVehicles ?? "0";
        this.checkedInDate = moment(
          this.reservation.arrivalDate,
          "MM/DD/YYYY"
        ).format("L");
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    cancel() {
      window.scrollTo(0, 0);
      this.$router.go(-1);
    },
    maxCheckIn(ymd) {
      if (
        moment(this.reservation.departureDate, "MM/DD/YYYY").isSameOrBefore(
          moment(),
          "days"
        )
      ) {
        return !moment(ymd).isBetween(
          moment(this.reservation.arrivalDate, "MM/DD/YYYY"),
          moment(this.reservation.departureDate, "MM/DD/YYYY"),
          "days",
          "[]"
        );
      } else {
        return !moment(ymd).isBetween(
          moment(this.reservation.arrivalDate, "MM/DD/YYYY"),
          moment(),
          "days",
          "[]"
        );
      }
    }
  },
  created() {
    this.getReservationDetail();
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
</style>
